import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'



Vue.use(CoreuiVue)
Vue.config.performance = true
Vue.prototype.$log = // console.log.bind(console)
Vue.prototype.apiLink = "https://simpel2.pertanian.go.id/endpoint/public/"
// Vue.prototype.apiLink = "http://localhost:8000/"
// Vue.prototype.apiLink = "https://simpel2-api.app.menungsa.com/"
Vue.prototype.access_token = localStorage.access_token
Vue.prototype.session_nama = localStorage.session_nama
Vue.prototype.session = localStorage.session
Vue.prototype.reload_already = localStorage.reload_already
Vue.component('v-select', VueSelect.VueSelect)
Vue.component('vue-multiselect', window.VueMultiselect.default)
Vue.use(HighchartsVue.default)
new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
